import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, ContentBlock, InteriorHero } from '@components';

const EventPageContainer = ({ data }) => {
  const pageTitle = data.contentfulEvents.title;
  const description = data.contentfulEvents.description.description;
  const { childContentfulEventsContentRichTextNode } = data.contentfulEvents;
  const html =
    childContentfulEventsContentRichTextNode?.childContentfulRichText?.html;

  return (
    <Layout {...data}>
      <SEO title={pageTitle} />
      <InteriorHero
        title={pageTitle}
        description={description}
        bgColor="orange"
      />
      <article className="interior-content-wrapper">
        <div className="page-wrapper">
          <ContentBlock content={html} />
        </div>
      </article>
    </Layout>
  );
};
export default EventPageContainer;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      slug
      title
      description {
        description
      }
      childContentfulEventsContentRichTextNode {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;
